.header {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #131921;
  position: sticky;
  top: 0;
  z-index: 100;
}
.header_logo {
  width: 100px;
  height: 50px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 13px;
  cursor: pointer;
}

.header_search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 24px;
}

.header_input {
  height: 12px;
  padding: 10px;
  border: none;
  width: 100%;
}

.header_icon {
  padding: 5px;
  height: 22px !important;
  background-color: #cd9042;
}

.header_nav {
  display: flex;
  justify-content: space-evenly;
}

.header_option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}

.header_link { text-decoration: none; }

.header_optionLineOne {
  font-size: 10px;
}

.header_optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}

.header_optionBasket {
  display: flex;
  align-items: center;
  color: white;
}

.header_basketCount {
  margin-left: 10px;
  margin-right: 10px;
}

@media(max-width:425px) {
  .header_nav {
    display: none;
  }
}